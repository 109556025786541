import React, { ReactElement } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import InlineField from '../Forms/InlineField';
import {MetaPaginationInfo} from "../api";

interface TablePaginationProps {
  setPageSize: (newPageSize: number) => void;
  pageSize: number;
  canPreviousPage: boolean;
  previousPage: () => void;
  gotoPage: (newPage: number) => void;
  pageOptions: number[];
  pageIndex: number;
  canNextPage: boolean;
  nextPage: () => void;
  pageCount: number;
  showRange?: boolean;
  showPaginate?: boolean;
  showNumRecords?: boolean;
  meta?: MetaPaginationInfo;
}

export const perPageOptions = [
  15,
  30,
  45,
  60,
  100,
];

const TablePagination = ({
  gotoPage,
  setPageSize,
  pageSize,
  canPreviousPage,
  previousPage,
  pageOptions,
  pageIndex,
  canNextPage,
  nextPage,
  pageCount,
  showRange = true,
  showPaginate = true,
  showNumRecords = true,
  meta,
}: TablePaginationProps): ReactElement => {
  let slicedPageOptions = pageOptions;
  if (pageOptions.length > 5) {
    // Find key that holds our current page
    const index = pageOptions.findIndex((subject) => subject === pageIndex);
    // Slice the array with 2 on either side.
    if (index < 2) {
      slicedPageOptions = pageOptions.slice(0, 5);
    } else {
      slicedPageOptions = pageOptions.slice(index - 2, index + 3);
    }
  }

  return (
    <div className="form-inline">
      {meta && showRange && (
        <Form.Label>Showing {meta.from ?? 0}-{meta.to ?? 0} of {meta.total ?? 0} records</Form.Label>
      )}
      {showPaginate && (
        <div className="pagination-container">
          <Pagination>
            <Pagination.First
              disabled={!canPreviousPage}
              onClick={() => gotoPage(0)}
            />
            <Pagination.Prev
              disabled={!canPreviousPage}
              onClick={previousPage}
            />
            {slicedPageOptions.map((option) => (
              <Pagination.Item
                key={option}
                disabled={option === pageIndex}
                onClick={() => gotoPage(option)}
              >
                {option + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={!canNextPage}
              onClick={nextPage}
            />
            <Pagination.Last
              disabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
            />
          </Pagination>
        </div>
      )}
      {showNumRecords && (
        <InlineField
          label="Show Records"
          type="select"
          inline
          reduced
          value={pageSize}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPageSize(e.target.value as any)}
        >
          {perPageOptions.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </InlineField>
      )}
    </div>
  );
};

export default TablePagination;
