import axios from 'axios';

const LoginRequest = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

export default LoginRequest;
