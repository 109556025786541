import React, {ReactElement, useContext} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {
  Field, Form, Formik, FormikHelpers,
} from 'formik';
import {SeasonReadinessFormFilters} from "../Interfaces/SeasonReadinessRequest";
import {AuthContext} from "../../auth/AuthContext";
import _ from "lodash";
import FullPageLoader from "../../Shared/FullPageLoader";

export const defaultSearchValues: SeasonReadinessFormFilters = {
  customer_name: '',
  distributor: '',
  status: '',
};

interface TableSearchProps {
  initialSearchValues: SeasonReadinessFormFilters;
  handleSearch: (values: SeasonReadinessFormFilters) => void;
}

const SeasonReadinessFormTableSearch = (
  {
    handleSearch,
    initialSearchValues,
  }: TableSearchProps,
): ReactElement => {
  const auth = useContext(AuthContext);

  const handleOnSubmit = (
    values: SeasonReadinessFormFilters, { setSubmitting }: FormikHelpers<SeasonReadinessFormFilters>,
  ): void => {
    handleSearch(values);
    setSubmitting(false);
  };
  const statuses = ["pending", "complete"];

  return (
    <div>
      <Formik
        initialValues={initialSearchValues}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ resetForm }): ReactElement => (
          <Form>
            <section>
              <h2>Search Season Readiness</h2>
              <Row>
                <Col xs="12" sm="3" className="mt-2">
                  <Field
                    name="customer_name" className="form-control" placeholder="Customer Name"
                  />
                </Col>
                {auth.is_nestle_client && (
                  <Col xs="12" sm="3" className="mt-2">
                    <Field
                      name="distributor" className="form-control" placeholder="Distributor"
                    />
                  </Col>
                )}
                <Col xs="12" sm="3" className="mt-2">
                  <Field className="form-control" name="status" as="select">
                    <option value="">Select...</option>
                    {statuses.map((value: string) => {
                      return (<option key={value} value={value}>{value}</option>)
                    })}
                  </Field>
                </Col>
                <Col xs="12" sm="3" className="mt-2 text-sm-right">
                  <Button
                    variant="outline-secondary"
                    type="button"
                    className="mr-3"
                    onClick={() => {
                      handleSearch(defaultSearchValues);
                      resetForm({ values: initialSearchValues });
                    }}
                  >
                    Clear
                  </Button>
                  <Button type="submit" title="Search">
                    Search
                  </Button>
                </Col>
              </Row>
            </section>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SeasonReadinessFormTableSearch;
