import ApiGatewayRequest from "../../Shared/ApiGatewayRequest";
import {QueryBuilderRequest, ResultSet} from "../../Shared/api";
import {SeasonReadinessFormFilters, SeasonReadinessFormRequestInclude} from "../Interfaces/SeasonReadinessRequest";
import axios, {AxiosPromise} from "axios";
import {
  SeasonReadinessForm,
  SeasonReadinessFormValues,
  SeasonReadinessImageValues,
  SeasonReadinessImportValues
} from "../Interfaces/types";
import {User} from "../../auth/Interfaces/types";

export default class SeasonReadinessService {
  public static getUser() {
    return ApiGatewayRequest.get<ResultSet<User>>('auth/user');
  }

  public static getSeasonReadinessForms(
    page: number,
    per_page: number,
    is_nestle_client: boolean,
    tenants: Array<number>,
    filter?: SeasonReadinessFormFilters,
  ) {
    const request_body: QueryBuilderRequest<SeasonReadinessFormFilters, SeasonReadinessFormRequestInclude> = {
      include: [],
      filter: filter ?? undefined,
    };
    return ApiGatewayRequest.get('season-readiness-forms',{
      params : {
        per_page,
        page,
        is_nestle_client,
        tenants,
        ...request_body,
      }
    });
  }

  public static getSeasonReadinessForm(
    id: string,
  ): AxiosPromise<ResultSet<SeasonReadinessForm>> {
    return ApiGatewayRequest.get('season-readiness-forms/' + id);
  }

  public static createSeasonReadinessForm(values: SeasonReadinessFormValues): AxiosPromise<ResultSet<SeasonReadinessForm>> {
    return ApiGatewayRequest.post('season-readiness-forms', {
      data: {
        values
      }
    });
  }

  public static updateSeasonReadinessForm(id: string, values: SeasonReadinessFormValues)
    : AxiosPromise<ResultSet<SeasonReadinessForm>> {
    return ApiGatewayRequest.put('season-readiness-forms/' + id, values);
  }

  public static deleteSeasonReadinessForm(id: string): AxiosPromise<ResultSet<SeasonReadinessForm>> {
    return ApiGatewayRequest.delete('season-readiness-forms/' + id);
  }

  public static restoreSeasonReadinessForm(id: string): AxiosPromise<ResultSet<SeasonReadinessForm>> {
    return ApiGatewayRequest.post('season-readiness-forms/' + id);
  }

  public static import(
    values: SeasonReadinessImportValues,
  ) {
    const formData = new FormData();
    formData.append('csv_file', values.csv_file);
    return ApiGatewayRequest.post('season-readiness-forms/import/assets', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  public static getExport(filter: SeasonReadinessFormFilters) {
    const request_body: QueryBuilderRequest<SeasonReadinessFormFilters, SeasonReadinessFormRequestInclude> = {
      include: [],
      filter: filter ?? undefined,
    };

    return ApiGatewayRequest.get('season-readiness-forms/export/assets', {
      headers: {
        'Cache-Control': 'no-cache',
      },
      params : {
        ...request_body,
      }
    });
  }

  public static getGeocode(url: string) {
    return axios.get(encodeURI(url));
  }

  public static createSeasonReadinessImage(values: SeasonReadinessImageValues) {
    const formData = new FormData();
    formData.append('season_readiness_forms_id', values.season_readiness_forms_id);
    formData.append('uploaded_file', values.uploaded_file);
    formData.append('csv_file', values.uploaded_file);
    return ApiGatewayRequest.post('season-readiness-images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  public static deleteSeasonReadinessImage(id: string) {
    return ApiGatewayRequest.delete('season-readiness-images/' + id);
  }

  public static getSeasonReadinessFormsMap(
    is_nestle_client: boolean,
    latitude: number,
    longitude: number,
    tenants: Array<number>,
  ) {
    return ApiGatewayRequest.get('season-readiness-forms/index/map',{
      params : {
        is_nestle_client,
        latitude,
        longitude,
        tenants,
      }
    });
  }
}
