// @ts-nocheck
import * as yup from 'yup';

const SeasonReadinessImageSchema = yup.object().shape({
  uploaded_file: yup.mixed()
    .required("An image file is required")
    .test("fileSize", "The file is too large. Max size 5MB", (value) => {
      return value && (value.size / 1024 / 1024) <= 5;
    })
});

export default SeasonReadinessImageSchema;
