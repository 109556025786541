import React, {ReactElement, useCallback, useContext, useEffect, useMemo, useState} from "react";
import _ from "lodash";
import {AuthContext} from "../../auth/AuthContext";
import {Badge, Button, Card, Col, Row as FormRow, Dropdown, DropdownButton} from "react-bootstrap";
import {SeasonReadinessForm} from "../Interfaces/types";
import {PaginatedResultSet} from "../../Shared/api";
import SeasonReadinessService from "../Services/SeasonReadinessService";
import Table, {FetchDataProps} from "../../Shared/Table/Table";
import {SeasonReadinessFormFilters} from "../Interfaces/SeasonReadinessRequest";
import SeasonReadinessFormTableSearch, {defaultSearchValues} from "../Components/SeasonReadinessFormTableSearch";
import {CellProps} from "react-table";
import FullPageLoader from "../../Shared/FullPageLoader";
import { Link } from "react-router-dom";
import {toast} from "react-toastify";
import ImportModal from "../Components/ImportModal";
import tableToCards from "../Services/TableToCards";
import mapboxgl from 'mapbox-gl';
// @ts-ignore
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {useHistory} from "react-router";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const renderQuickActions = (uuid: string): ReactElement => {
  return (
    <Link to={'season-readiness/' + uuid}>
      <Button variant="primary">
        Edit
      </Button>
    </Link>
  );
};

const SeasonReadinessFormsIndex = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [seasonReadinessForms, setSeasonReadinessForms] = useState<PaginatedResultSet<SeasonReadinessForm[]>>();
  const [searchFilters, setSearchFilters] = useState<SeasonReadinessFormFilters>(defaultSearchValues);
  const [savedPageData, setSavedPageData] = useState<FetchDataProps>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mapControlsLoaded, setMapControlsLoaded] = useState<boolean>(false);
  const [map, setMap] = useState<mapboxgl.Map|undefined>(undefined);
  const [defaultLat, setDefaultLat] = useState<number>(51.049999);
  const [defaultLong, setDefaultLong] = useState<number>(-114.066666);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  const userTenants = useMemo<number[]>(
    () => {
      const tenants: number[] = [];
      if (!auth.is_nestle_client) {
        auth.user.tenant_users.forEach((tenantUser) => {
          tenants.push(tenantUser.tenant.id);
        });
      }
      return tenants;
    },
    [auth.is_nestle_client, auth.user],
  );

  const handleCloseModal = () => {
    setShowModal(false);
    if (savedPageData !== undefined) {
      getData(savedPageData);
      getMapForms(defaultLat, defaultLong, userTenants);
    }
  };

  const getDataPoints = (e: any) => {
    if (e.target !== undefined && e.isSourceLoaded) {
      const {lat, lng} = e.target.getCenter();
      getMapForms(lat, lng, userTenants);
    }
  };

  const getMapData = () => {
    if (mapLoaded) {
      return;
    }
    // @ts-ignore
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    setMap(new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [defaultLong, defaultLat],
      zoom: 10,
    }));
    setMapLoaded(true);
  };

  const getCurrentLocation = () => {
    if (map !== undefined) {
      navigator.geolocation.getCurrentPosition((position) => {
        setDefaultLat(position.coords.latitude);
        setDefaultLong(position.coords.longitude);
        map.setCenter([position.coords.longitude, position.coords.latitude]);
      });
    }
  };

  const getMapForms = (latitude: number, longitude: number, tenants: Array<number>) => {
    SeasonReadinessService.getSeasonReadinessFormsMap(
      auth.is_nestle_client,
      latitude,
      longitude,
      tenants
    ).then((response) => {
      if (map === undefined || map.getCanvasContainer() === undefined) {
        return;
      }

      response.data.data.forEach((info : SeasonReadinessForm) => {
        const coordinates: Array<number> = [info.customer_longitude, info.customer_latitude];
        let el = document.createElement('div');
        el.className = 'marker';
        let statusBadge = (info.status === 'complete') ? `<span class="badge badge-success">${info.status}</span>`
          : `<span class="badge badge-warning text-white">${info.status}</span>` ;

        const innerHtmlContent = `
          <h4 class="mb-1">${info.customer_name}</h4>
          <p class="mb-1">${formatAddress(info.address_1, info.address_2, info.city, info.province, info.postal_code, info.country)}</p>
          <p class="mb-1">${statusBadge}</p>
        `;

        const divElement = document.createElement('div');
        const assignBtn = document.createElement('div');
        assignBtn.innerHTML = `<button class="btn btn-primary text-white btn-sm block">Edit</button>`;
        divElement.innerHTML = innerHtmlContent;
        divElement.appendChild(assignBtn);
        assignBtn.addEventListener('click', (e) => {
          history.push('season-readiness/' + info.uuid);
        });

        new mapboxgl.Marker(el)
          // @ts-ignore
          .setLngLat(coordinates)
          .setPopup(new mapboxgl.Popup({ offset: 20 })
            .setDOMContent(divElement))
          .addTo(map);
      });
    }).catch(() => {
      toast.error('Failed to load map data. Please refresh the page');
    });
  };

  const addMapControls = () => {
    if (map === undefined || map.getCanvasContainer() === undefined) {
      return;
    }
    if (mapControlsLoaded) {
      return;
    }
    map.addControl(
      new MapboxGeocoder({
        placeholder: 'Search address/location',
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
      }).on('result', (results:any) => {
        if (results.result !== undefined && results.result.center !== undefined) {
          getMapForms(results.result.center[1], results.result.center[0], userTenants);
          setDefaultLat(results.result.center[1]);
          setDefaultLong(results.result.center[0]);
        }
      })
    );
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
    );
    map.on('sourcedata', getDataPoints);
    setMapControlsLoaded(true);
  };

  const formatAddress = (
    address_1: any,
    address_2: any,
    city: any,
    province: any,
    postal_code: any,
    country: any,
  ) => {
    let address = '';
    if (address_1 !== null && !_.isEmpty(address_1)) {
      address += ` ${address_1}`;
    }
    if (address_2 !== null && !_.isEmpty(address_2)) {
      address += ` ${address_2}`;
    }
    if (city !== null && !_.isEmpty(city)) {
      address += ` ${city}`;
    }
    if (province !== null && !_.isEmpty(province)) {
      address += ` ${province}`;
    }
    if (postal_code !== null && !_.isEmpty(postal_code)) {
      address += ` ${postal_code}`;
    }
    if (country !== null && !_.isEmpty(country)) {
      address += ` ${country}`;
    }
    return address;
  };

  const exportForms = (status: string) => {
    let exportDefaultValues = defaultSearchValues;
    exportDefaultValues.status = status;
    SeasonReadinessService.getExport(exportDefaultValues).then((response) => {
      let blob = new Blob([response.data], { type: 'text/csv' });
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `season_readiness_export_${Date.now()}.csv`;
      link.click()
    }).catch(() => {
      toast.error("Failed to export");
    });
  };

  const getData = (pageData: FetchDataProps) => {
    SeasonReadinessService.getUser()
      .then(({ data: { data } }) => {
        SeasonReadinessService.getSeasonReadinessForms(
          pageData.pageNumber,
          pageData.pageSize,
          auth.is_nestle_client,
          userTenants,
          searchFilters
        ).then((response) => {
          setSeasonReadinessForms(response.data);
          tableToCards();
          setSavedPageData(pageData);
        }).catch(() => {
          toast.error('Failed to load data. Please refresh the page');
        });
      });
  };

  useEffect(() => {
    getMapData();
    getCurrentLocation();
    window.addEventListener('resize', tableToCards);
    return () => {
      window.removeEventListener('resize', tableToCards);
    }
  }, [mapLoaded]);

  const handleFetchData = useCallback(async (pageData: FetchDataProps) => {
    getData(pageData);
  }, [searchFilters]);

  if (map !== undefined) {
    getMapForms(defaultLat, defaultLong, userTenants);
    addMapControls();
  }

  return (
    <>
      <section>
        <FormRow>
          <Col>
            <Card className="mb-3 mt-3">
              <Card.Body className="map-body">
                <div id="map"/>
              </Card.Body>
            </Card>
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <SeasonReadinessFormTableSearch
                  initialSearchValues={searchFilters}
                  handleSearch={setSearchFilters}
                />
              </Card.Body>
            </Card>
          </Col>
        </FormRow>
        <FormRow>
          <Col xs="12">
            <Card>
              <Card.Body>
                {auth.is_nestle_client && (
                  <div>
                    <div className="mb-3 text-sm-right">
                      <DropdownButton className="mr-3 mb-3 d-inline-block" variant="outline-primary" title="Export Season Readiness">
                        <Dropdown.Item onClick={() => exportForms('')}>All</Dropdown.Item>
                        <Dropdown.Item onClick={() => exportForms('pending')}>Pending</Dropdown.Item>
                        <Dropdown.Item onClick={() => exportForms('complete')}>Completed</Dropdown.Item>
                      </DropdownButton>
                      <Button variant="primary" className="d-inline-block" onClick={() => setShowModal(true)}>
                        Import Season Readiness
                      </Button>
                    </div>
                    <Table<SeasonReadinessForm>
                      id="nestle_table"
                      fetchData={handleFetchData}
                      columns={[
                        {
                          Header: 'Actions',
                          Cell: ({ row: { original: { uuid } } }: CellProps<SeasonReadinessForm>) => {
                            return renderQuickActions(uuid);
                          },
                        },
                        {
                          Header: 'Distributor',
                          accessor: 'distributor' as 'distributor',
                        },
                        {
                          Header: 'Customer Name',
                          accessor: 'customer_name' as 'customer_name',
                        },
                        {
                          Header: 'Customer Number',
                          accessor: 'nestle_customer_number' as 'nestle_customer_number',
                        },
                        {
                          Header: 'Customer Address',
                          Cell: ({ row: { original: { address_1, address_2, city, province, postal_code, country } } }
                                   : CellProps<SeasonReadinessForm>) => {
                            return (
                              <>
                                {formatAddress(address_1, address_2, city, province, postal_code, country)}
                              </>
                            );
                          },
                        },
                        {
                          Header: 'Status',
                          Cell: ({ row: { original: { status } } }
                                   : CellProps<SeasonReadinessForm>) => {
                            return (
                              <>
                                {status === 'pending' &&(
                                  <Badge className="text-white" variant="warning">{status}</Badge>
                                )}
                                {status === 'complete' &&(
                                  <Badge variant="success">{status}</Badge>
                                )}
                              </>
                            );
                          },
                        },
                        {
                          Header: 'Mandatory',
                          Cell: ({ row: { original: { mandatory } } }
                                   : CellProps<SeasonReadinessForm>) => {
                            return (
                              <>
                                {mandatory == '1' &&(
                                  <span>Mandatory</span>
                                )}
                              </>
                            );
                          },
                        },
                        {
                          Header: 'Submission Date',
                          accessor: 'submitted_at' as 'submitted_at',
                        },
                        {
                          Header: 'Submission Location',
                          accessor: 'submission_location' as 'submission_location',
                        },
                      ]}
                      responseData={seasonReadinessForms ?? undefined}
                      pageCount={seasonReadinessForms?.meta.last_page ?? 0}
                    />
                  </div>
                )}

                {!auth.is_nestle_client && (
                  <Table<SeasonReadinessForm>
                    id="distributor_table"
                    fetchData={handleFetchData}
                    columns={[
                      {
                        Header: 'Action',
                        id: 'actions',
                        Cell: ({ row: { original: { uuid } } }: CellProps<SeasonReadinessForm>) => {
                          return renderQuickActions(uuid);
                        },
                      },
                      {
                        Header: 'Customer Name',
                        accessor: 'customer_name' as 'customer_name',
                      },
                      {
                        Header: 'Customer Number',
                        accessor: 'nestle_customer_number' as 'nestle_customer_number',
                      },
                      {
                        Header: 'Customer Address',
                        Cell: ({ row: { original: { address_1, address_2, city, province, postal_code, country } } }
                                 : CellProps<SeasonReadinessForm>) => {
                          return (
                            <>
                              {formatAddress(address_1, address_2, city, province, postal_code, country)}
                            </>
                          );
                        },
                      },
                      {
                        Header: 'Status',
                        Cell: ({ row: { original: { status } } }
                                 : CellProps<SeasonReadinessForm>) => {
                          return (
                            <>
                              {status === 'pending' &&(
                                <Badge className="text-white" variant="warning">{status}</Badge>
                              )}
                              {status === 'complete' &&(
                                <Badge variant="success">{status}</Badge>
                              )}
                            </>
                          );
                        },
                      },
                      {
                        Header: 'Mandatory',
                        Cell: ({ row: { original: { mandatory } } }
                                 : CellProps<SeasonReadinessForm>) => {
                          return (
                            <>
                              {mandatory == '1' &&(
                                <span>Mandatory</span>
                              )}
                            </>
                          );
                        },
                      },
                      {
                        Header: 'Submission Date',
                        accessor: 'submitted_at' as 'submitted_at',
                      },
                      {
                        Header: 'Submission Location',
                        accessor: 'submission_location' as 'submission_location',
                      },
                    ]}
                    responseData={seasonReadinessForms ?? undefined}
                    pageCount={seasonReadinessForms?.meta.last_page ?? 0}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </FormRow>
      </section>
      <ImportModal show={showModal} handleClose={handleCloseModal}/>
    </>
  );
};

export default SeasonReadinessFormsIndex;
