import LoginRequest from "../../Shared/LoginRequest";
import {ResultSet} from "../../Shared/api";
import {User} from "../../auth/Interfaces/types";

export default class LoginService {
  public static getUser() {
    return LoginRequest.get<ResultSet<User>>('/api/auth/user');
  }

  public static async login(email: string, password: string) {
    await LoginRequest.get('/sanctum/csrf-cookie');

    return LoginRequest.post(`/login`, {
      email, password,
    });
  }

  public static async logout() {
    return LoginRequest.post(`/logout`);
  }
}
