import React, {useContext} from "react";
import {AuthContext} from "../../auth/AuthContext";
import {Button, Form, Navbar, Nav} from "react-bootstrap";

const MasterNavbar = () => {
  const auth = useContext(AuthContext);

  const logoutHandler = (): void => {
    auth.logout();
  }

  if (!auth.isLoggedIn) {
    return <></>;
  }

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/" className="navbar-link">Season Readiness</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="mt-3">
          <Nav className="mr-auto"/>
          <Form inline>
            <Button variant="outline-info" onClick={logoutHandler}>Logout</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default MasterNavbar;
