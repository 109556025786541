import React, { ReactElement } from 'react';

interface SectionProps {
  children: React.ReactNode | ReactElement | ReactElement[];
  fullWidth?: boolean;
  nestedContent?: boolean;
  first?: boolean;
  last?: boolean;
  compressed?: boolean;
}

const Section = (
  {
    nestedContent = false,
    children,
    fullWidth = false,
    first = false,
    last = false,
    compressed = false,
  }: SectionProps,
): ReactElement => {
  return (
    <div className={`field-block ${nestedContent ? 'nested' : ''} ${fullWidth ? 'full-width' : ''} ${first ? 'first' : ''} ${last ? 'last' : ''} ${compressed ? 'compressed' : ''}`}>
      {children}
    </div>
  );
};

export default Section;
