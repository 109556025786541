import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = () => {
  return (
    <>
      <ToastContainer limit={5}/>
    </>
  );
};

export default Notification;
