// @ts-nocheck
import * as yup from 'yup';

const LoginSchema = yup.object().shape({
  email: yup.string()
    .email('Must be a valid email address')
    .required('Email is required'),
  password: yup.string()
    .required('Passowrd is required'),
});

export default LoginSchema;
