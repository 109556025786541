import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import Notification from "./Shared/Notification";

ReactDOM.render(
  <BrowserRouter>
    <Notification />
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
