import React, { ReactElement } from 'react';
import {FormGroup, FormLabel} from "react-bootstrap";
import {ErrorMessage, Field} from "formik";

interface SeasonReadinessRadioProps {
  name: string;
  label: string;
}

const SeasonReadinessRadio = (
  {
    name,
    label,
  }: SeasonReadinessRadioProps,
): ReactElement => {
  return (
    <>
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        <div role="group" aria-labelledby="my-radio-group">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <Field className="form-check-input" type="radio" name={name} value="1" />
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <Field className="form-check-input" type="radio" name={name} value="0" />
              No
            </label>
          </div>
        </div>
        <ErrorMessage className="text-danger" name={name} component="div" />
      </FormGroup>
    </>
  );
};

export default SeasonReadinessRadio;
