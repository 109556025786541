import React, { ReactElement } from 'react';

interface ValidationErrorsProps {
  errors: Array<any>;
}

const ValidationErrors = (
  {
    errors,
  }: ValidationErrorsProps,
): ReactElement => {
  errors.forEach((messages: Array<string>) => {
    messages.reverse();
  });
  return (
    <div className="mb-1">
      {errors.map((messages: Array<string>) => (
        messages.map((message: string) => (
          <p className="mb-1 font-weight-normal" key={message}>{message}</p>
        ))
      ))}
    </div>
  );
};

export default ValidationErrors;
