// @ts-nocheck
import React, {
  PropsWithChildren, ReactElement, useEffect, useState,
} from 'react';
import BootstrapTable from 'react-bootstrap/Table';
import {
  Cell,
  HeaderGroup, IdType,
  Row,
  TableOptions, TableState,
  usePagination,
  useTable,
} from 'react-table';
import FontAwesome from 'react-fontawesome';
import TablePagination, { perPageOptions } from './TablePagination';
import EmptyContainer from '../Layout/EmptyContainer';
import Section from '../Layout/Section';
import {PaginatedResultSet} from "../api";
import FullPageLoader from "../FullPageLoader";

export interface TableProps<D extends object = {}> extends Omit<TableOptions<D>, 'data'> {
  responseData?: PaginatedResultSet<D[]>;
  fetchData: (pageData: FetchDataProps) => Promise<void>;
  rowRender?: (row: Row<D>) => ReactElement|ReactElement[];
  pageCount?: number;
  paginateTop?: boolean;
  paginateBottom?: boolean;
  forceRefresh?: boolean;
  onRefresh?: () => void;
  hiddenColumns?: Array<IdType<D>>;
  showHeader?: boolean;
  emptyTable?: React.ReactNode;
  id?: string;
}

// TODO We should just change these to page and per_page to align
//  with the variables expected by back end. This way we're not
//  constantly re-assigning them.
export interface FetchDataProps {
  pageNumber: number;
  pageSize: number;
}

const hooks = [
  usePagination,
];

const defaultPageOption = perPageOptions[0];

export const defaultClassName = (id: string): string => {
  return id === 'actions'
    ? 'table-actions table-actions--actions'
    : '';
};
export const defaultHeaderClassName = (id: string): string => {
  return id === 'actions'
    ? 'table-actions table-actions--header'
    : '';
};

export const defaultRowRender = <D extends {}>(row: Row<D>) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell: Cell<D>) => {
      return (
        <td
          {...cell.getCellProps()}
          className={defaultClassName(cell.column.id)}
        >
          {cell.render('Cell')}
        </td>
      );
    })}
  </tr>
);

const DefaultEmptyTable = (): ReactElement => {
  return (
    <Section first last>
      <EmptyContainer icon={<FontAwesome name="info-circle" />} title="No Records Available" />
    </Section>
  );
};

const Table = <D extends object>(
  {
    columns,
    responseData,
    fetchData,
    rowRender,
    pageCount: controlledPageCount,
    forceRefresh = false,
    onRefresh,
    hiddenColumns,
    paginateTop = false,
    paginateBottom = true,
    emptyTable = <DefaultEmptyTable />,
    showHeader = true,
    id,
  }: PropsWithChildren<TableProps<D>>): ReactElement => {
  const initialState: Partial<TableState<D>> = { pageIndex: 0, pageSize: defaultPageOption };
  if (hiddenColumns) {
    initialState.hiddenColumns = hiddenColumns;
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    setHiddenColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    setPageSize,
    previousPage,
  } = useTable<D>(
    {
      columns,
      data: responseData?.data ?? [],
      initialState,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    ...hooks,
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setHiddenColumns(hiddenColumns ?? []);
  }, [hiddenColumns]);

  useEffect(() => {
    setLoading(true);
    fetchData({ pageNumber: pageIndex + 1, pageSize }).finally(
      () => setLoading(false),
    );
    if (onRefresh && forceRefresh) {
      onRefresh();
    }
  }, [fetchData, pageIndex, pageSize, onRefresh, forceRefresh]);

  return (
    <div style={{ position: 'relative' }}>
      {loading && <FullPageLoader />}
      {responseData && responseData.data.length > 0
        ? (
          <>
            {paginateTop && (
              <div className="table--end">
                <TablePagination
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  canPreviousPage={canPreviousPage}
                  previousPage={previousPage}
                  gotoPage={gotoPage}
                  pageOptions={pageOptions}
                  pageIndex={pageIndex}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  pageCount={pageCount}
                  meta={responseData?.meta}
                />
              </div>
            )}
            <BootstrapTable id={id} {...getTableProps()} responsive>
              {showHeader && (
                <thead className="thead-dark">
                  {headerGroups.map((headerGroup: HeaderGroup<D>) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        <th
                          {...column.getHeaderProps()}
                          className={defaultHeaderClassName(column.id)}
                        >
                          {column.id !== 'actions' && column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody {...getTableBodyProps()}>
                {page.map((row: Row<D>) => {
                  prepareRow(row);
                  return (
                    rowRender
                      ? rowRender(row)
                      : defaultRowRender(row));
                })}
              </tbody>
            </BootstrapTable>
            {paginateBottom && (
              <div className="table--end">
                <TablePagination
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  canPreviousPage={canPreviousPage}
                  previousPage={previousPage}
                  gotoPage={gotoPage}
                  pageOptions={pageOptions}
                  pageIndex={pageIndex}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  pageCount={pageCount}
                  meta={responseData?.meta}
                />
              </div>
            )}
          </>
        )
        : (
          <>{emptyTable}</>
        )}
    </div>
  );
};

export default Table;
