import React from "react";
import './../../login.css'
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {LoginFormValues} from "../Interfaces/types";
import {FormGroup, FormLabel} from "react-bootstrap";
import { toast } from 'react-toastify';
import LoginService from "../../auth/Services/LoginService";
import LoginSchema from "../Interfaces/LoginValidationSchema";

const initialLoginFormValues: LoginFormValues = {
  email: '',
  password: '',
};

interface LoginFormProps {
  handleLogin: () => void;
}

const LoginForm = ({ handleLogin }: LoginFormProps) => {
  const submitLogin = (
    { email, password }: LoginFormValues,
  ) => LoginService.login(email, password)
    .then(handleLogin)
    .catch(() => toast.error('Invalid Credentials'));

  return (
    <Formik
      initialValues={initialLoginFormValues}
      validationSchema={LoginSchema}
      onSubmit={submitLogin}
    >
      {({ isSubmitting }) => (
        <Form className="overflow-hidden">
          <div className="row no-gutter">
            <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"/>
            <div className="col-md-8 col-lg-6">
              <div className="login d-flex align-items-center py-5">
                <div className="container">
                  <div className="row">
                    <div className="col-md-9 col-lg-8 mx-auto">
                      <h3 className="login-heading mb-4">Season Readiness</h3>
                        <FormGroup>
                          <FormLabel>Email address</FormLabel>
                          <Field type="email" name="email" className="form-control" placeholder="Email address"/>
                          <ErrorMessage className="text-danger" name="email" component="div" />
                        </FormGroup>

                        <FormGroup>
                          <FormLabel>Password</FormLabel>
                          <Field type="password" name="password" className="form-control" placeholder="Password"/>
                          <ErrorMessage className="text-danger" name="password" component="div" />
                        </FormGroup>

                        <button
                          className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                          type="submit" disabled={isSubmitting}
                        >
                          Sign in
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
