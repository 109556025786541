import React, { ReactElement } from 'react';
import {FormGroup, FormLabel} from "react-bootstrap";
import {ErrorMessage, Field} from "formik";

interface SeasonReadinessRadioQuestionProps {
  formValue: any;
  name: string;
  whyName: string;
  label: string;
}

const SeasonReadinessRadioQuestion = (
  {
    formValue,
    name,
    whyName,
    label,
  }: SeasonReadinessRadioQuestionProps,
): ReactElement => {
  return (
    <>
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        <div role="group" aria-labelledby="my-radio-group">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <Field className="form-check-input" type="radio" name={name} value="1" />
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <Field className="form-check-input" type="radio" name={name} value="0" />
              No
            </label>
          </div>
        </div>
        <ErrorMessage className="text-danger" name={name} component="div" />
      </FormGroup>
      {(formValue !== null && formValue as string === '0') && (
        <FormGroup>
          <Field
            name={whyName}
            className="mt-3 form-control"
            rows={3}
            as="textarea"
            placeholder="If No, Why? Please provide comments"
          />
          <ErrorMessage className="text-danger" name={whyName} component="div" />
        </FormGroup>
      )}
    </>
  );
};

export default SeasonReadinessRadioQuestion;
