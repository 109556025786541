import axios from 'axios';
const qs = require('qs');

const ApiGatewayRequest = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + '/api/',
  paramsSerializer: (p) => {
    return qs.stringify(p, { arrayFormat: 'brackets' });
  },
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

const reloadStatuses = [
  401, 403, 419,
];

ApiGatewayRequest.interceptors.response.use(undefined, async (error) => {
  if (error.response && reloadStatuses.indexOf(error.response.status) >= 0) {
    window.location.replace('/');
  }

  return Promise.reject(error);
});

export default ApiGatewayRequest;
