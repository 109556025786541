import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';

interface InlineFieldProps {
  label?: string;
  inline?: boolean;
  reduced?: boolean;
  children?: ReactElement|ReactElement[];
  type?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
}

const InlineField = (
  {
    label,
    inline = false,
    reduced = false,
    type,
    children,
    onChange,
    value,
  }: InlineFieldProps,
) => {
  return (
    <Form.Group className={`${inline ? 'inline' : ''} ${reduced ? 'reduced' : ''}`}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as={type}
        custom
        onChange={onChange}
        value={value}
      >
        {children}
      </Form.Control>
    </Form.Group>
  );
};

export default InlineField;
