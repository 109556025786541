import React, { ReactElement } from 'react';
import FontAwesome from 'react-fontawesome';

interface EmptyTableProps {
  icon?: ReactElement;
  title: string;
  action?: ReactElement;
}

const EmptyContainer = (
  {
    icon = <FontAwesome name="info-circle" />,
    title,
    action,
  }: EmptyTableProps,
) => {
  return (
    <div className="empty-container">
      <div className="empty-container--icon">{icon}</div>
      <div className="empty-container--details">
        <div className="empty-container--title">{title}</div>
        <div className="empty-container--action">{action}</div>
      </div>
    </div>
  );
};

export default EmptyContainer;
