import React, {ReactElement, useState} from 'react';
import {FormGroup, FormLabel, Row, Col, Button, InputGroup} from "react-bootstrap";
import {ErrorMessage, Field, FieldArrayRenderProps} from "formik";
import {SeasonReadinessAsset} from "../Interfaces/types";
import BarcodeScanner from "../../Shared/Forms/BarcodeScanner";

const SeasonReadinessRadio = (
  {
    form: { values, setFieldValue },
    remove,
  }: FieldArrayRenderProps,
): ReactElement => {
  const [showScannerModal, setShowScannerModal] = useState<boolean>(false);
  const [scannerFieldName, setScannerFieldName] = useState<string>('');

  const handleCloseScannerModal = () => {
    setShowScannerModal(false);
  };

  const handleScannedBarcode = (barcode: string) => {
    setShowScannerModal(false);
    setFieldValue(scannerFieldName, barcode);
  };

  const handleOpenScannerModal = (name: string) => {
    setShowScannerModal(true);
    setScannerFieldName(name);
  };

  const labels = {
    asset_type: 'What is the size of the freezer?',
    asset_serial_number: 'What is the Asset Serial Number?',
    adequate_temperature: 'Is the freezer at an adequate temperature (approximately -20 degrees Celsius)?',
    broken_freezer: 'Is the Nestle blue freezer broken down/damaged/malfunctioning?',
  }
  const sizeOfFreezerValues : Array<string> = [
    "6 SLT - Small Floor Freezer",
    "10 SLT – Small Floor Freezer",
    "15 SLT – Medium Floor Freezer",
    "22 SLT – Large Floor Freezer",
    "13 CF – Small Upright",
    "22 CF – Medium Upright",
    "HD UCC – Front of Cash unit",
    "Counter Top – Front of Cash unit",
    "4 FT – Small Scooping Freezer",
    "6 FT – Medium Scooping Freezer",
    "8 Ft – Large Scooping Freezer",
  ];

  return (
    <>
      {values.assets.map((asset: SeasonReadinessAsset, index: number) => {
        return (
          <div key={index} className="mb-5 mt-5">
            <Row className="mb-1">
              <Col xs="7" sm="9" md="9" lg="10">
                <h4 className="border-bottom mb-3">
                  For Asset #{index + 1}
                </h4>
              </Col>
              <Col xs="5" sm="3" md="3" lg="2">
                <Button
                  block
                  variant="danger"
                  onClick={() => {remove(index);}}
                  className="mb-3"
                >
                  Remove
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6">
                <FormGroup>
                  <FormLabel>{labels['asset_type']}</FormLabel>
                  <Field className="form-control" name={`assets.${index}.asset_type`} as="select">
                    <option value="">Select...</option>
                    {sizeOfFreezerValues.map((value: string) => {
                      return (<option key={value} value={value}>{value}</option>)
                    })}
                  </Field>
                  <ErrorMessage className="text-danger" name={`assets.${index}.asset_type`} component="div" />
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup>
                  <FormLabel>{labels['asset_serial_number']}</FormLabel>
                  <InputGroup>
                    <Field name={`assets.${index}.asset_serial_number`} className="form-control" placeholder="Asset Serial Number"/>
                    <InputGroup.Append>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => handleOpenScannerModal(`assets.${index}.asset_serial_number`)}
                      >
                        Scan Barcode
                      </button>
                    </InputGroup.Append>
                  </InputGroup>
                  <ErrorMessage className="text-danger" name={`assets.${index}.asset_serial_number`} component="div" />
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <FormLabel>{labels['broken_freezer']}</FormLabel>
                  <div role="group" aria-labelledby="my-radio-group">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <Field className="form-check-input" type="radio" name={`assets.${index}.broken_freezer`} value="1" />
                        Yes, did you ask the store manager to report breakdown/malfunction by calling the 1-888-763-7853
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <Field className="form-check-input" type="radio" name={`assets.${index}.broken_freezer`} value="0" />
                        No, it is in good working condition
                      </label>
                    </div>
                  </div>
                  <ErrorMessage className="text-danger" name={`assets.${index}.broken_freezer`} component="div" />
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <FormLabel>{labels['adequate_temperature']}</FormLabel>
                  <div role="group" aria-labelledby="my-radio-group">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <Field className="form-check-input" type="radio" name={`assets.${index}.adequate_temperature`} value="1" />
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <Field className="form-check-input" type="radio" name={`assets.${index}.adequate_temperature`} value="0" />
                        No
                      </label>
                    </div>
                  </div>
                  <ErrorMessage className="text-danger" name={`assets.${index}.adequate_temperature`} component="div" />
                </FormGroup>
              </Col>
            </Row>
          </div>
        );
      })}
      <BarcodeScanner
        show={showScannerModal}
        handleClose={handleCloseScannerModal}
        handleScannedBarcode={handleScannedBarcode}
      />
    </>
  );
};

export default SeasonReadinessRadio;
