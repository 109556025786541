import React, {useState, useEffect, useMemo} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SeasonReadinessFormsIndex from './readiness/Pages/SeasonReadinessFormsIndex';
import LoginForm from './auth/Pages/LoginForm';
import {AuthContext, AuthContextValues} from './auth/AuthContext';
import {Container} from "react-bootstrap";
import FullPageLoader from "./Shared/FullPageLoader";
import { Route, Switch } from 'react-router-dom';
import SeasonReadiness from "./readiness/Pages/SeasonReadiness";
import MasterNavbar from "./readiness/Pages/MasterNavbar";
import { toast } from 'react-toastify';
import LoginService from "./auth/Services/LoginService";
import {User} from "./auth/Interfaces/types";
import _ from "lodash";

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getUser = () => {
    setIsFetching(true);
    LoginService
      .getUser()
      .then((response) => setUser(response.data.data))
      .finally(() => setIsFetching(false));
  }

  useEffect(() => {
    getUser();
  }, []);

  const is_nestle_client = useMemo<boolean>(
    () => {
      const tenants = (user?.tenant_users ?? []).map(({ tenant }) => tenant);

      const found = _.find(tenants, { id: 8 });

      return Boolean(found);
    },
    [user],
  );

  const contextValues = useMemo<AuthContextValues>(
    () => ({
      isLoggedIn: Boolean(user),
      logout: async () => {
        setUser(null);
        await LoginService.logout();
        toast.success("Logged out successfully");
      },
      // @ts-ignore
      user,
      is_nestle_client,
    }),
    [user, is_nestle_client],
  );

  if (isFetching) {
    return <FullPageLoader />;
  }

  if (!contextValues.user) {
    return <LoginForm handleLogin={getUser} />;
  }

  return (
    <AuthContext.Provider value={contextValues}>
      <MasterNavbar />
      <Container fluid>
        <Switch>
          <Route path="/season-readiness/:id">
            <SeasonReadiness />
          </Route>
          <Route path="/">
            <SeasonReadinessFormsIndex />
          </Route>
        </Switch>
      </Container>
    </AuthContext.Provider>
  );
}

export default App;
