// @ts-nocheck
import * as yup from 'yup';

const SeasonReadinessFormSchema = yup.object().shape({
  require_sr: yup.string().nullable().required('Please select an option'),
  why_no_require_sr: yup.string().nullable(),
  single_serve_ice_cream_status: yup.string().nullable().required('Please select an option'),
  take_home_ice_cream_status: yup.string().nullable().required('Please select an option'),
  scrape_excess_ice_and_clean: yup.string().nullable().required('Please select an option'),
  why_no_scrape_excess_ice_and_clean: yup.string().nullable(),
  clean_freezers: yup.string().nullable().required('Please select an option'),
  why_no_clean_freezers: yup.string().nullable(),
  planogram_freezer: yup.string().nullable().required('Please select an option'),
  why_no_planogram_freezer: yup.string().nullable(),
  install_header_card: yup.string().nullable().required('Please select an option'),
  why_no_install_header_card: yup.string().nullable(),
  install_price_cling: yup.string().nullable().required('Please select an option'),
  why_no_install_price_cling: yup.string().nullable(),
  install_basket_clip_inserts: yup.string().nullable().required('Please select an option'),
  why_no_install_basket_clip_inserts: yup.string().nullable(),
  install_take_home_ic_price_cling: yup.string().nullable(),
  why_no_install_take_home_ic_price_cling: yup.string().nullable(),
  planogram_take_home_ice_cream: yup.string().nullable(),
  why_no_planogram_take_home_ice_cream: yup.string().nullable(),
  credit_done_for_expired_damaged_product: yup.string().nullable().required('Please select an option'),
  secure_order_with_innovations: yup.string().nullable().required('Please select an option'),
  assets: yup.array()
    .of(
      yup.object().shape({
        asset_type: yup.string().nullable().required('Please select an option'),
        asset_serial_number: yup.string().nullable().required('Please enter a serial number'),
        adequate_temperature: yup.string().nullable().required('Please select an option'),
        broken_freezer: yup.string().nullable().required('Please select an option'),
      }),
    ).nullable(),
});

export default SeasonReadinessFormSchema;
