import React, {ReactElement, useState} from 'react';
import {Button, FormGroup, Modal} from "react-bootstrap";
import {Form, Formik, ErrorMessage, FormikHelpers} from "formik";
import {SeasonReadinessImageValues} from "../Interfaces/types";
import SeasonReadinessService from "../Services/SeasonReadinessService";
import {toast} from "react-toastify";
import SeasonReadinessImageSchema from "../Interfaces/SeasonReadinessImageSchema";
import ImageUploader from 'react-images-upload';

interface ImportModalProps {
  srId: string;
  show: boolean;
  handleClose: () => void;
}

const initialImageValues: SeasonReadinessImageValues = {
  uploaded_file: null,
  season_readiness_forms_id: null,
};

const UploadImageModal = (
  {
    srId,
    show,
    handleClose,
  }: ImportModalProps,
): ReactElement => {
  const handleSubmit = (
    values: SeasonReadinessImageValues,
    actions: FormikHelpers<SeasonReadinessImageValues>,
  ) => {
    values.season_readiness_forms_id = srId;
    SeasonReadinessService.createSeasonReadinessImage(values)
      .then((response) => {
        toast.success('Uploaded image successfully');
        handleClose();
      })
      .catch(() => {
        toast.error('Failed to upload image.');
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Formik
          initialValues={initialImageValues}
          validationSchema={SeasonReadinessImageSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Upload an Image</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup>
                  <ImageUploader
                    withIcon={true}
                    buttonText='Choose images'
                    onChange={(files) => setFieldValue('uploaded_file', files[0])}
                    imgExtension={['.jpg', '.gif', '.png', 'jpeg']}
                    singleImage={true}
                    maxFileSize={5242880}
                    withPreview={true}
                    withLabel={true}
                  />
                  <ErrorMessage className="text-danger" name="uploaded_file" component="div" />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Upload
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UploadImageModal;
