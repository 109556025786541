import React, {useEffect, useRef, useState} from 'react';
import { WasmDecoder } from "@impactdk/barcode-scanner";
import { ReactBarcodeScanner } from "@impactdk/react-barcode-scanner";
import {Modal, Alert} from "react-bootstrap";
import { isBarcodeScannerSupported } from "@impactdk/barcode-scanner/lazy";

interface BarcodeScannerProps {
  show: boolean;
  handleClose: () => void;
  handleScannedBarcode: (barcode: string) => void;
}

const BarcodeScanner = (
  {
    show,
    handleClose,
    handleScannedBarcode,
  }: BarcodeScannerProps,
) => {
  const { current: decoder } = useRef(WasmDecoder.getInstance(process.env.PUBLIC_URL + "/wasm"));
  const [webcamAvailable, setWebcamAvailable] = useState<boolean>(true);
  const [barcodeScannerNotSupported, setBarcodeScannerNotSupported] = useState<boolean>(true);

  const handleBarcode = (barcode: string) => {
    handleScannedBarcode(barcode);
  };

  useEffect(() => {
    setBarcodeScannerNotSupported(isBarcodeScannerSupported);

    try {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => setWebcamAvailable(true))
        .catch(() => setWebcamAvailable(false));
    } catch(err) {
      setWebcamAvailable(false)
    }
  }, []);

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Scan Barcode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!webcamAvailable && (
            <Alert variant="danger">
              Unable to scan barcode because camera is not available. Please allow usage of your camera.
            </Alert>
          )}
          {!barcodeScannerNotSupported && (
            <Alert variant="danger">
              Barcode scanner not supported on this device.
            </Alert>
          )}
          <ReactBarcodeScanner
            decoder={decoder}
            onFindBarcode={handleBarcode}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BarcodeScanner;
