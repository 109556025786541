import React, {ReactElement, useState} from 'react';
import {Button, FormGroup, Modal} from "react-bootstrap";
import {Form, Formik, ErrorMessage, FormikHelpers} from "formik";
import {SeasonReadinessImportValues} from "../Interfaces/types";
import SeasonReadinessImportSchema from "../Interfaces/SeasonReadinessImportSchema";
import SeasonReadinessService from "../Services/SeasonReadinessService";
import {toast} from "react-toastify";
import ValidationErrors from "../../Shared/ValidationErrors";

interface ImportModalProps {
  show: boolean;
  handleClose: () => void;
}

const initialImportValues: SeasonReadinessImportValues = {
  csv_file: null,
};

const ImportModal = (
  {
    show,
    handleClose,
  }: ImportModalProps,
): ReactElement => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>([]);

  const handleSubmit = (
    values: SeasonReadinessImportValues,
    actions: FormikHelpers<SeasonReadinessImportValues>,
  ) => {
    setIsValidating(true);
    SeasonReadinessService.import(values)
      .then((response) => {
        toast.success('Imported data successfully');
        handleClose();
        setIsValid(true);
      })
      .catch((errors) => {
        toast.error('Failed to import. Review errors and try again');
        if (errors.response.data.errors !== undefined) {
          setErrors(errors.response.data.errors);
          setIsValid(false);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsValidating(false);
      });
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Formik
          initialValues={initialImportValues}
          validationSchema={SeasonReadinessImportSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Import Season Readiness</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup>
                  <label htmlFor="file">Select a CSV file</label>
                  <input accept=".csv,text/csv" id="csv_file" name="file" type="file" onChange={(event) => {
                    // @ts-ignore
                    setFieldValue("csv_file", event.currentTarget.files[0]);
                  }} className="form-control" />
                  <ErrorMessage className="text-danger" name="csv_file" component="div" />
                </FormGroup>
                {!isValidating && !isValid && (errors.map((error: Array<any>, key: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ValidationErrors key={key} errors={error} />
                )))}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Import
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ImportModal;
