import React, {useContext, useState, useEffect} from "react";
import _ from "lodash";
import {AuthContext} from "../../auth/AuthContext";
import {Button, Card, Col, FormGroup, FormLabel, Row, Image} from "react-bootstrap";
import { useParams, useHistory } from 'react-router';
import SeasonReadinessService from "../Services/SeasonReadinessService";
import {
  SeasonReadinessAsset,
  SeasonReadinessForm,
  SeasonReadinessFormValues,
  SeasonReadinessImage
} from "../Interfaces/types";
import FullPageLoader from "../../Shared/FullPageLoader";
import {ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers} from "formik";
import {toast} from "react-toastify";
import SeasonReadinessFormSchema from "../Interfaces/SeasonReadinessSchema";
import SeasonReadinessRadioQuestion from "../Components/SeasonReadinessRadioQuestion";
import SeasonReadinessRadio from "../Components/SeasonReadinessRadio";
import SeasonReadinessAssets from "../Components/SeasonReadinessAssets";
import UploadImageModal from "../Components/UploadImageModal";
import Viewer from 'react-viewer';

export interface EditParams {
  id: string;
}

const SeasonReadiness = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const { id } = useParams<EditParams>();
  const [seasonReadinessForm, setSeasonReadinessForm] = useState<SeasonReadinessForm|undefined>();
  const [otherRequireSrComment, setOtherRequireSrComment] = useState<string>('');
  const [submissionLatitude, setSubmissionLatitude] = useState<number>();
  const [submissionLongitude, setSubmissionLongitude] = useState<number>();
  const [submissionLocation, setSubmissionLocation] = useState<string>();
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [srImages, setSrImages] = useState<Array<any>>([]);
  const whyNoRequireSrValues : Array<string> = [
    "Closed permanently",
    "Closed temporarily (i.e. renovations, limited hours)",
    "Not at his time, Seasonal Account",
    "No IC assets in store",
    "Other, please provide comments",
  ];
  const singleServeIceCreamStatusValues : Array<string> = [
    "Nestle Exclusive",
    "Shared with GHB",
    "Shared with another competitor",
    "Shared with GHB and another competitor",
  ];
  const takeHomeIceCreamStatusValues : Array<string> = [
    "Customer does not sell take home",
    "Nestle Exclusive",
    "Shared with another competitor",
  ];

  const formatAddress = (
    address_1: any,
    address_2: any,
    city: any,
    province: any,
    postal_code: any,
    country: any,
  ) => {
    let address = '';
    if (address_1 !== null && !_.isEmpty(address_1)) {
      address += ` ${address_1}`;
    }
    if (address_2 !== null && !_.isEmpty(address_2)) {
      address += ` ${address_2}`;
    }
    if (city !== null && !_.isEmpty(city)) {
      address += ` ${city}`;
    }
    if (province !== null && !_.isEmpty(province)) {
      address += ` ${province}`;
    }
    if (postal_code !== null && !_.isEmpty(postal_code)) {
      address += ` ${postal_code}`;
    }
    if (country !== null && !_.isEmpty(country)) {
      address += ` ${country}`;
    }
    return address;
  };

  const transformNumbers = (obj : any) => {
    Object.keys(obj).forEach(function(key){
      if (obj[key] !== null && typeof obj[key] === 'number') {
        obj[key] = String(obj[key]);
      }
    });

    return obj;
  };

  const handleCloseImageModal = () => {
    getSeasonReadinessForm();
    setOpenImageModal(false);
  };

  const getSeasonReadinessForm = () => {
    SeasonReadinessService.getSeasonReadinessForm(id)
      .then((response) => {
        transformSeasonReadinessData(response.data.data);
      }).catch(() => {
      toast.error("Failed to get form data. Please refresh the page")
    });
  };

  const handleDeleteImage = (id: string) => {
    if (window.confirm('Are you sure you wish to delete this image?')) {
      SeasonReadinessService.deleteSeasonReadinessImage(id)
        .then(() => {
          getSeasonReadinessForm();
          toast.success('Removed image successfully');
        }).catch(() => {
        toast.error("Failed to delete image");
      });
    }
  };

  const transformSeasonReadinessData = (formData: SeasonReadinessForm) => {
    formData.assets.forEach((asset) => {
      transformNumbers(asset)
    })
    const data = transformNumbers(formData);
    if (data.why_no_require_sr !== undefined && data.why_no_require_sr !== null && data.why_no_require_sr.length > 0
      && data.why_no_require_sr.includes("Other, please provide comments")) {
      const whyNoRequireSr = data.why_no_require_sr.split('-');
      data.why_no_require_sr = whyNoRequireSr[0].trim();
      setOtherRequireSrComment(whyNoRequireSr[1].trim());
    }

    setSeasonReadinessForm(data);
    setSrImages([]);
    data.images.forEach((img: SeasonReadinessImage) => {
      const imgObj = {
        src: `data:image/png;base64,${img.file_path}`,
        alt: img.file_name
      }

      setSrImages((srImages) => [...srImages, imgObj]);
    })
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setSubmissionLatitude(position.coords.latitude);
        setSubmissionLongitude(position.coords.longitude);
        getSubmissionLocation(position.coords.latitude, position.coords.longitude);
      });
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  const getSubmissionLocation = (latitude: number, longitude: number) => {
    const addressUrl = `${longitude},${latitude}`;
    const url = `${process.env.REACT_APP_MAPBOX_URL}/${addressUrl}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
    SeasonReadinessService.getGeocode(url).then((response) => {
      const places = response['data']['features'];
      if (response['data']['features'].length > 0) {
        setSubmissionLocation(places[0]['place_name']);
      }
    });
  };

  const handleOtherRequireSrCommentChange = (event : any) => {
    setOtherRequireSrComment(event.target.value);
  };

  const handleAddSchedule = (
    push: (obj: any) => void,
  ) => {
    if (seasonReadinessForm !== undefined) {
      const newRequest: SeasonReadinessAsset = {
        id: null,
        season_readiness_forms_id: seasonReadinessForm.id,
        asset_serial_number: '',
        asset_type: '',
        manuf_serial_no: '',
        material: '',
        material_desc: '',
        manufacturer: '',
        adequate_temperature: '0',
        broken_freezer: '0',
      };
      push(newRequest);
    }
  };

  const generateInitialValues = (seasonReadinessForm: SeasonReadinessForm): SeasonReadinessFormValues => {
    return {
      ...seasonReadinessForm,
    };
  };

  useEffect(() => {
    getLocation();
    getSeasonReadinessForm();
  }, [id]);

  if (seasonReadinessForm === undefined) {
    return <FullPageLoader />;
  }

  const handleSubmit = (
    values: SeasonReadinessFormValues,
    actions: FormikHelpers<SeasonReadinessFormValues>,
  ): void => {
    if (values.why_no_require_sr === 'Other, please provide comments') {
      values.why_no_require_sr += ' - ' + otherRequireSrComment;
    }

    if (
      submissionLatitude !== undefined &&
      submissionLongitude !== undefined
    ) {
      values.submission_latitude = submissionLatitude;
      values.submission_longitude = submissionLongitude;
      if (submissionLocation !== undefined) {
        values.submission_location = submissionLocation;
      }
    }

    values.submitted_by = `${authContext.user.first_name} ${authContext.user.last_name}`;

    SeasonReadinessService.updateSeasonReadinessForm(id, values)
      .then(() => {
        history.push(`/`);
        toast.success('Submitted successfully');
      }).catch(() => {
      toast.error('Failed to submit. Please try again');
    }).finally(() => {
      actions.setSubmitting(false);
    });
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Season Readiness</h1>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col sm="12">
                  <h2 className="mb-3">Customer</h2>
                </Col>
                <Col sm="4">
                  <h5 className="border-bottom font-weight-light">Name</h5>
                  <p>{seasonReadinessForm.customer_name}</p>
                </Col>
                <Col sm="4">
                  <h5 className="border-bottom font-weight-light">Customer Number</h5>
                  <p>{seasonReadinessForm.nestle_customer_number}</p>
                </Col>
                <Col sm="4">
                  <h5 className="border-bottom font-weight-light">Address</h5>
                  <p>
                    {formatAddress(
                      seasonReadinessForm.address_1,
                      seasonReadinessForm.address_2,
                      seasonReadinessForm.city,
                      seasonReadinessForm.province,
                      seasonReadinessForm.postal_code,
                      seasonReadinessForm.country
                    )}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col xs="12">
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col sm="12">
                  <h2 className="mb-3">Last Updated By</h2>
                </Col>
                <Col sm="4">
                  <h5 className="border-bottom font-weight-light">Name</h5>
                  <p>{seasonReadinessForm.submitted_by}</p>
                </Col>
                <Col sm="4">
                  <h5 className="border-bottom font-weight-light">Updated at</h5>
                  <p>
                    {seasonReadinessForm.updated_at}
                  </p>
                </Col>
                <Col sm="4">
                  <h5 className="border-bottom font-weight-light">Location</h5>
                  <p>
                    {seasonReadinessForm.submission_location}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col xs="12">
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col xs="7" sm="9" md="9" lg="10" className="mb-4">
                  <h2 className="mb-0">
                    Images
                  </h2>
                  <span className="font-weight-light font-size-small">(click on images to enlarge)</span>
                </Col>
                <Col xs="5" sm="3" md="3" lg="2">
                  <Button block onClick={() => {setOpenImageModal(true)}}>
                    Add
                  </Button>
                </Col>
                <Col xs="12">
                  <Row>
                  {seasonReadinessForm.images.map((image: SeasonReadinessImage) => {
                    return (
                      <Col key={image.id} xs="12" sm="6" md="4" lg="3" className="text-center mb-3">
                        <Image
                          className="cursor-pointer max-height-200 mb-1"
                          onClick={() => setVisible(true)}
                          src={`data:image/png;base64,${image.file_path}`}
                          fluid
                        />
                        <Button variant="danger" block onClick={() => {handleDeleteImage(image.id)}}>
                          Remove
                        </Button>
                      </Col>
                      )
                    })}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col xs="12">
          <Card className="mb-3">
            <Card.Body>
              <h2 className="mb-3">Reporting</h2>
              <Formik
                initialValues={generateInitialValues(seasonReadinessForm)}
                validationSchema={SeasonReadinessFormSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values }) => (
                  <Form>
                    <h5 className="border-bottom mb-3 mt-4">Status of Store</h5>
                    <FormGroup>
                      <FormLabel>Does the store require Season Readiness?</FormLabel>
                      <div role="group" aria-labelledby="my-radio-group">
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <Field className="form-check-input" type="radio" name="require_sr" value="1" />
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <Field className="form-check-input" type="radio" name="require_sr" value="0" />
                            No
                          </label>
                        </div>
                      </div>
                      <ErrorMessage className="text-danger" name="require_sr" component="div" />
                    </FormGroup>
                    {(values.require_sr !== null && values.require_sr === '0') && (
                      <FormGroup>
                        <FormLabel>If No, Why?</FormLabel>
                        <Field className="form-control" name="why_no_require_sr" as="select">
                          <option value="">Select...</option>
                          {whyNoRequireSrValues.map((value: string) => {
                            return (<option key={value} value={value}>{value}</option>)
                          })}
                        </Field>
                        {values.why_no_require_sr === 'Other, please provide comments' && (
                          <textarea
                            value={otherRequireSrComment}
                            onChange={handleOtherRequireSrCommentChange}
                            className="mt-3 form-control"
                            rows={3}
                            placeholder="Please provide comments"
                          />
                        )}
                        <ErrorMessage className="text-danger" name="why_no_require_sr" component="div" />
                      </FormGroup>
                    )}

                    <h5 className="border-bottom mb-3 mt-4">Competition</h5>
                    <FormGroup>
                      <FormLabel>Select the criteria which best describes the customer’s single-serve ice cream status?</FormLabel>
                      <Field className="form-control" name="single_serve_ice_cream_status" as="select">
                        <option value="">Select...</option>
                        {singleServeIceCreamStatusValues.map((value: string) => {
                          return (<option key={value} value={value}>{value}</option>)
                        })}
                      </Field>
                      <ErrorMessage className="text-danger" name="single_serve_ice_cream_status" component="div" />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Select the criteria which best describes the customer’s take home ice cream status?</FormLabel>
                      <Field className="form-control" name="take_home_ice_cream_status" as="select">
                        <option value="">Select...</option>
                        {takeHomeIceCreamStatusValues.map((value: string) => {
                          return (<option key={value} value={value}>{value}</option>)
                        })}
                      </Field>
                      <ErrorMessage className="text-danger" name="take_home_ice_cream_status" component="div" />
                    </FormGroup>

                    <FieldArray name="assets">
                      {(arrayHelpers) => (
                        <Row className="mb-4">
                          <Col xs="7" sm="9" md="9" lg="10" className="mb-2">
                            <h2 className="border-bottom mb-3 mt-4">
                              Assets
                            </h2>
                          </Col>
                          <Col xs="5" sm="3" md="3" lg="2">
                            <Button className="mt-4" block onClick={() => handleAddSchedule(arrayHelpers.push)}>
                              Add
                            </Button>
                          </Col>
                          <Col xs="12">
                            <SeasonReadinessAssets
                              {...arrayHelpers}
                            />
                          </Col>
                        </Row>
                      )}
                    </FieldArray>

                    <h2 className="border-bottom mb-3 mt-4">In Store Single Serve Execution</h2>
                    <SeasonReadinessRadioQuestion
                      formValue={values.scrape_excess_ice_and_clean}
                      name="scrape_excess_ice_and_clean"
                      whyName="why_no_scrape_excess_ice_and_clean"
                      label="Did you scrape excess ice and clean the bottom of the freezers?"
                    />
                    <SeasonReadinessRadioQuestion
                      formValue={values.clean_freezers}
                      name="clean_freezers"
                      whyName="why_no_clean_freezers"
                      label="Did you clean the freezers (glass railing, facings)?"
                    />
                    <SeasonReadinessRadioQuestion
                      formValue={values.planogram_freezer}
                      name="planogram_freezer"
                      whyName="why_no_planogram_freezer"
                      label="Did you planogram the freezer?"
                    />
                    <SeasonReadinessRadioQuestion
                      formValue={values.install_header_card}
                      name="install_header_card"
                      whyName="why_no_install_header_card"
                      label="Did you install the header card?"
                    />
                    <SeasonReadinessRadioQuestion
                      formValue={values.install_price_cling}
                      name="install_price_cling"
                      whyName="why_no_install_price_cling"
                      label="Did you install the price cling?"
                    />
                    <SeasonReadinessRadioQuestion
                      formValue={values.install_basket_clip_inserts}
                      name="install_basket_clip_inserts"
                      whyName="why_no_install_basket_clip_inserts"
                      label="Did you install the basket clip inserts?"
                    />
                    <SeasonReadinessRadioQuestion
                      formValue={values.install_take_home_ic_price_cling}
                      name="install_take_home_ic_price_cling"
                      whyName="why_no_install_take_home_ic_price_cling"
                      label="If applicable, did you install the take home IC price cling on the upright freezer?"
                    />
                    <SeasonReadinessRadioQuestion
                      formValue={values.planogram_take_home_ice_cream}
                      name="planogram_take_home_ice_cream"
                      whyName="why_no_planogram_take_home_ice_cream"
                      label="If applicable, did you planogram the take home ice cream in the upright freezer?"
                    />
                    <SeasonReadinessRadio
                      name="credit_done_for_expired_damaged_product"
                      label="Was a credit done for expired/damaged product and were products destroyed/removed from store?"
                    />
                    <SeasonReadinessRadio
                      name="secure_order_with_innovations"
                      label="Did you secure an order with this year's innovations?"
                    />

                    <Row>
                      <Col>
                        <Button
                          variant="outline-secondary"
                          block
                          type="button"
                          href="/"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          block
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Viewer
        visible={visible}
        onClose={() => { setVisible(false); } }
        images={srImages}
      />

      <UploadImageModal srId={seasonReadinessForm.id} show={openImageModal} handleClose={handleCloseImageModal}/>
    </>
  );
};

export default SeasonReadiness;
