import { createContext } from "react";
import {User} from "./Interfaces/types";

export interface AuthContextValues {
  isLoggedIn: boolean;
  user: User;
  logout: () => void;
  is_nestle_client: boolean;
}

export const AuthContext = createContext<AuthContextValues>({} as AuthContextValues);
